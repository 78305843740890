<template>
    <div class="cooperativePartner">
        <div class="info">
            <div>合作伙伴</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CooperativePartner"
    }
</script>

<style scoped>
    .cooperativePartner{
        width: 100%;
        height: 600px;
        background-image: url("../../assets/imgs/test2.png");
        background-size: cover;
        background-position: center;
        position: relative;
    }
    .cooperativePartner .info{
        width: 100%;
        text-align: center;
        position: absolute;
        top: 70px;
    }
    .cooperativePartner .info div{
        display: inline-block;
        font-size: 44px;
        line-height: 60px;
        color: #ffffff;
    }
</style>
<template>
    <div class="index">
        <!--轮播图区域-->
        <div class="banners">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in imgs">
                        <i :style="{backgroundImage:'url(/images/website/' + item.image_url + ')'}"></i>
                        <div class="txt-box">
                            <div class="txt-box-title">{{item.name}}</div>
                            <div class="txt-box-brief">{{item.content}}</div>
                        </div>
                    </div>
                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>

                <!-- 如果需要导航按钮 -->
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>

                <!-- 如果需要滚动条 -->
                <div class="swiper-scrollbar"></div>
            </div>
        </div>
        <!--方案区域-->
        <div class="solution">
            <div class="solution-main" v-if="obj.firstbackground" :style="{backgroundImage:'url(/images/website/' + obj.firstbackground + ')'}">
                <div class="solution-txt-box">
                    <div class="solution-txt-box-title">物联网解决方案</div>
                    <div class="solution-txt-box-more">
                        <span @click="goto">了解更多 >></span>
                    </div>
                </div>
            </div>
        </div>
        <public-footer />
        <!--<div class="img-link">
            <ul>
                <li>
                    <div style="background-image: url('/imgs/Witness.jpg')">
                        <p class="img-txt">客户案例</p>
                    </div>
                </li>
                <li>
                    <div style="background-image: url('/imgs/Company.jpg')">
                        <p class="img-txt">关于哈工精密</p>
                    </div>
                </li>
            </ul>
        </div>-->
        <!--<cooperative-partner/>-->
    </div>
</template>

<script>
    import cooperativePartner from '@/components/index/CooperativePartner'
    import publicFooter from '@/components/common/PublicFooter'

    export default {
        name: "index",
        components: {
            cooperativePartner,
            publicFooter,
        },
        data() {
            return {
                obj:{}, //公司信息
                flag: 0,    //控制图片文字是否上移的真假
                flag2: 0,    //控制图片文字是否上移的真假
                imgs: [],   //轮播数组
            }
        },
        created(){
            this.getBanners();
            this.getCompanyInfo();
        },
        mounted() {
            document.onscroll = ()=> {
                if (document.documentElement.scrollTop > 99 && this.flag == 0) {
                    this.changeMove('.solution-txt-box-title','flag');
                }else if(document.documentElement.scrollTop > 800 && this.flag2 == 0){
                    this.changeMove('.img-txt','flag2');
                }
            }

        },
        methods: {
            //获取公司信息
            async getCompanyInfo(){
                if(this.$store.getters.getCompanyInfo){
                    this.obj = this.$store.getters.getCompanyInfo;
                }else{
                    try {
                        const {data} = await this.$axios('/api/companyprofile');
                        if(data.code){
                            this.obj = data.data[0];
                            this.$store.dispatch('setCompanyInfo',data.data[0]);
                        }
                    }catch (e) {
                        //
                    }
                }
            },
            //获取轮播
            async getBanners(){
                try {
                    const {data} = await this.$axios('/api/carousel/show');
                    if(data.code){
                        this.imgs = data.data;
                        setTimeout(()=>{
                            new Swiper('.swiper-container', {
                                loop: true,
                                speed:2000,
                                autoplay: 3000,
                                autoplayDisableOnInteraction: false,
                                // 如果需要分页器
                                pagination: '.swiper-pagination',
                                // 如果需要前进后退按钮
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev',
                            });
                        },100);
                    }
                }catch (e) {
                    //
                }
            },
            //控制文字上移
            changeMove(element,flag) {
                this[flag] = 1;
                Array.from(document.querySelectorAll(element)).forEach(el=>{
                    el.style.transform = 'translateY(0)';
                    el.style.opacity = 1;
                });
            },
            //点击跳转到方案
            goto(){
                this.$router.push({name:'solution'});
            },
        },
    }
</script>

<style scoped>
    .index {}

    .banners {
        margin-bottom: 15px;
    }

    .swiper-container {
        width: 100%;
        height: 710px;
    }

    .swiper-container .swiper-button-prev {
        background-image: url("../assets/imgs/left.png");
        height: 25px;
    }

    .swiper-container .swiper-button-next {
        background-image: url("../assets/imgs/right.png");
    }

    .swiper-container i {
        display: block;
        width: 100%;
        height: 710px;
        background-position: center;
        background-size: cover;
    }

    .swiper-container .txt-box {
        color: #ffffff;
        position: absolute;
        top: 140px;
        width: 100%;
        text-align: center;
    }

    .swiper-container .txt-box-title {
        font-size: 50px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 80px;
        margin-bottom: 10px;
    }

    .swiper-container .txt-box-brief {
        font-size: 30px;
        line-height: 48px;
        letter-spacing: 4px;
    }

    .solution {
        margin-bottom: 15px;
    }

    .solution .solution-main {
        width: 100%;
        height: 675px;
        /*background-image: url("/imgs/solution.jpg");*/
        background-size: 100% auto;
        position: relative;
    }

    .solution .solution-main .solution-txt-box {
        width: 100%;
        position: absolute;
        top: 100px;
        color: #ffffff;
        text-align: center;
    }

    .solution .solution-main .solution-txt-box-title {
        font-size: 50px;
        font-weight: 700;
        letter-spacing: 2px;
        text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
        line-height: 80px;
        transition: all 1s;
        transform: translateY(30%);
        opacity: 0;
        margin-bottom: 20px;
    }

    .solution .solution-main .solution-txt-box-more {}

    .solution .solution-main .solution-txt-box-more span {
        padding: 6px 20px;
        border: 1px solid #ffffff;
        border-radius: 50px;
        cursor: pointer;
    }
    .solution .solution-main .solution-txt-box-more span:hover {
        background: rgba(255, 255, 255, 0.3);
    }

    .img-link{
        padding: 0 15px;
        box-sizing: border-box;
        /*margin-bottom: 15px;*/
    }
    .img-link ul{
        display: flex;
        flex-wrap: wrap;
    }
    .img-link ul li{
        flex: 1;
        width: 50%;
        height: 350px;
        margin-right: 15px;
        margin-bottom: 15px;
        overflow: hidden;
    }
    .img-link ul li:nth-child(2n){
        margin-right: 0;
    }
    .img-link ul li div:hover{
        transform: scale(1.05) translateZ(0);
    }
    .img-link ul li div{
        line-height:350px;
        color: #fff;
        font-weight: 700;
        font-size: 50px;
        text-align: center;
        text-shadow: 0 2px 4px rgb(0 0 0 / 50%);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: all .5s;
    }
    .img-link ul li div p.img-txt{
        transition: all 1s;
        transform: translateY(10%);
        opacity: 0;
    }
</style>